import React, { useEffect, useState } from 'react'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import classNames from 'classnames'

import HambugerMenu from './components/BurgerMenu'
import LanguageSelector from './components/LanguageSelector'

import { Locale } from '../../types'

import styles from './styles.module.css'
import { links } from './components/links'

type HeaderProps = {
  links?: {
    to: string
    label: string
  }[]
  isLandingPage?: boolean
  bgColor?: string
  location: Location
  locale: Locale
}

const Header = ({
  isLandingPage = true,
  locale,
  location,
}: HeaderProps): JSX.Element => {
  const [isOnTop, setIsOnTop] = useState(true)

  const scrollHandler = () => {
    if (typeof window !== 'undefined') {
      const scrollTop =
        window.scrollY ||
        window.pageYOffset ||
        document.body?.scrollTop +
        ((document.documentElement && document.documentElement.scrollTop) ||
          0)

      if (scrollTop > 5) {
        if (isOnTop) {
          setIsOnTop(false)
        }
      } else if (!isOnTop) {
        setIsOnTop(true)
      }
    }
  }
  useEffect(() => {
    document.addEventListener('scroll', scrollHandler)
    return () => {
      document.removeEventListener('scroll', scrollHandler)
    }
  }, [isOnTop])

  return (
    <div className="">
      <nav
        className={`hidden max-w-screen-15inchLaptop mx-auto lg:flex justify-center pt-12  md:py-10 xl:py-10 px-4 uppercase tracking-normal text-base sm:text-lg md:text-xl lg:text-2x ${isLandingPage
            ? 'md:justify-between pb-32'
            : 'relative md:justify-between flex-wrap pb-24'
          }`}
        role="navigation"
      >
        {isLandingPage && (
          <>
            <ul className="flex md:pl-10">
              {links[locale].map(({ to, label }) => (
                <li className="p-3 hover:scale-110" role={label} key={to}>
                  <AnchorLink to={to} title={label} />
                </li>
              ))}
            </ul>
            {/* <AnchorLink
              to="/"
              title="EN"
              className="w-3/6 text-center md:w-auto self-center font-arcosBold uppercase leading-none xl:mr-10"
            /> */}
            <LanguageSelector
              locale={locale}
              origin={location.origin}
              pathname={location.pathname}
            />
          </>
        )}
        {!isLandingPage && (
          <>
            <ul className="flex-3 flex md:pl-10">
              {links[locale].map(({ to, label }) => (
                <li className="p-3 hover:scale-110" role={label} key={to}>
                  <AnchorLink to={to} title={label} />
                </li>
              ))}
            </ul>
            <div className="flex items-center">
              <AnchorLink
                to="/"
                title="studio midos"
                className={classNames(
                  'w-3/6 text-center md:w-auto self-center font-arcosBold uppercase leading-none xl:mr-10',
                  styles.logo,
                )}
              />
              <LanguageSelector
                locale={locale}
                origin={location.origin}
                pathname={location.pathname}
              />
            </div>
          </>
        )}
      </nav>
      <HambugerMenu
        isOnTop={isOnTop}
        locale={locale}
        location={location}
      />
    </div>
  )
}

export default Header
