import React from "react"

import InstagramSvg from '../../assets/svg/instagram.svg'
import FacebookSvg from '../../assets/svg/facebook-logo.svg'
import PinterestSvg from '../../assets/svg/pinterest.svg'

type FooterProps = {
  locale: 'fi' | 'en'
}
const Footer = ({ locale }: FooterProps): JSX.Element => {
  return (
    <div className="max-w-screen-15inchLaptop mx-auto sm:flex uppercase tracking-wide pb-16 md:pb-10 bg-primary text-center">
      <div className="flex-1 self-center text-center px-4 sm:px-8 lg:px-10 text-xs sm:text-sm md:text-lg">
        <p className="font-arcosRegular">
          {' '}
          {locale === 'fi' ? 'Ota yhteyttä' : 'Contact'}
        </p>
        <p className="font-arcosRegular mb-5 sm:mb-0">
          info(at)studiomidos.com
        </p>
      </div>
      <h1 className="flex-1 self-center justify-self-center text-3xl md:text-3xl xl:text-5xl mb-10 sm:mb-0">
        Studio Midos
      </h1>
      <div className="flex-1 self-center flex justify-center px-4">
        <a
          href="https://www.instagram.com/studiomidos/"
          target="_blank"
          rel="noopener noreferrer"
          aria-hidden="true"
        >
          <InstagramSvg className="mx-1 lg:mx-2 w-6 md:w-7" />
        </a>
        <a
          href="https://www.facebook.com/studiomidos/photos/?ref=page_internal"
          target="_blank"
          rel="noopener noreferrer"
          aria-hidden="true"
        >
          <FacebookSvg className="mx-1 lg:mx-2 w-6 md:w-7" />
        </a>
        <a
          href="https://ar.pinterest.com/StudioMidos/"
          target="_blank"
          rel="noopener noreferrer"
          aria-hidden="true"
        >
          <PinterestSvg className="mx-1 lg:mx-2 w-6 md:w-7" />
        </a>
      </div>
    </div>
  )
}

export default Footer
