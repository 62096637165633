import React, { useState } from 'react'
import classNames from 'classnames'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

import HambugerMenuIcon from '../../../../assets/svg/hambuger-menu.svg'
import CloseIcon from '../../../../assets/svg/close.svg'
import LanguageSelector from '../LanguageSelector'

import { links } from '../links'
import { Locale } from '../../../../types'

import styles from './styles.module.css'

type MenuProps = {
  isOnTop: boolean
  location: Location
  locale: Locale
}

const HambugerMenu = ({ isOnTop, locale, location }: MenuProps) => {
  const [isOpen, setIsOpen] = useState(false)

  const navigationStyles = classNames({
    [styles.nav_menu_wrapper]: true,
    [styles.menu_top]: isOnTop && !isOpen,
  })

  const menuStyles = classNames({
    [styles.nav_menu]: true,
    [styles.nav_menu_open]: isOpen,
  })
  const handleOpenMenu = () => setIsOpen(!isOpen)

  return (
    <div className={navigationStyles}>
      <div className={styles.nav_actions}>
        <button className={!isOpen ? styles.menu_button : styles.menu_button_close} onClick={handleOpenMenu} style={{ width: '20px' }}>
          <HambugerMenuIcon />
        </button>
        <button className={isOpen ? styles.menu_button : styles.menu_button_close} onClick={handleOpenMenu} style={{ width: '20px' }}>
          <CloseIcon />
        </button>
      </div>
      <nav aria-label="Mobile Main Navigation" className={menuStyles}>
        <ul className="ml-6 mt-16">
          {links[locale].map(({ to, label }) => (
            <li className="uppercase text-3xl mb-4" key={to} onClick={handleOpenMenu}>
              <AnchorLink key={to} to={to} title={label} />
            </li>
          ))}
          <LanguageSelector
            locale={locale}
            origin={location.origin}
            pathname={location.pathname}
          />
        </ul>
      </nav>
    </div>
  )
}

export default HambugerMenu
