import React from "react"

import Header from "../Header"
import Footer from "../Footer"

import "../../assets/styles/main.css"

type LayoutProps = {
  children: React.ReactNode
  location: Location
  locale: 'fi' | 'en'
}

const Layout = ({ children, location, locale }: LayoutProps) => {
  return (
    <>
      <div className="overflow-x-hidden bg-primary">
        <Header location={location} locale={locale} />
        {children}
        <Footer locale={locale} />
      </div>
    </>
  )
}

export default Layout
