import React from 'react'
import { graphql, StaticQuery } from 'gatsby'

import { Locale } from '../../../../types'

type Props = {
  locale: Locale
  pathname: string
  origin: string
}

type Links = {
  links: {
    edges: {
      node: {
        path: string
        context: {
          nextLocalPage: string
          customPath: string
        }
      }
    }[]
  }
}

const LanguageSelector = ({ pathname, locale }: Props) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          links: allSitePage {
            edges {
              node {
                path
                context {
                  nextLocalPage
                  customPath
                }
              }
            }
          }
        }
      `}
      render={(data: Links) => {
        const hasTrailSlash = pathname.charAt(pathname.length - 1) === '/'
        const addTrailSlash = !hasTrailSlash ? `${pathname}/` : pathname
        const origin =
          typeof window !== 'undefined' ? window.location.origin : ''
        const [to] = data.links.edges.filter(({ node }) => {
          return node.path === addTrailSlash
        })
        const nextPath = to?.node.context.nextLocalPage || '/'
        const URL = `${origin}${nextPath}`
        return (
          <div>
            {
              locale === 'fi' &&
              <a href={URL} className="w-3/6 text-3xl lg:text-2xl text-center md:w-auto self-center uppercase leading-none xl:mr-10">
                EN
              </a>
            }
            {
              locale === 'en' &&
              <a href={URL} className="w-3/6 text-3xl lg:text-2xl text-center md:w-auto self-center uppercase leading-none xl:mr-10">
                FI
              </a>
            }
          </div>
        )
      }}
    />
  )
}

export default LanguageSelector
