export const links = {
  en: [
    {
      to: '/en/#palvelut',
      label: 'Services',
    },
    {
      to: '/en/projects',
      label: 'Projects',
    },
    {
      to: '/en/#otayhteytta',
      label: 'Contact',
    },
  ],
  fi: [
    {
      to: '/#palvelut',
      label: 'Palvelut',
    },
    {
      to: '/projektit',
      label: 'PROJEKTIT',
    },
    {
      to: '/#otayhteytta',
      label: 'Ota yhteyttä',
    },
  ],
}
