import React from "react"
import Layout from "../components/Layout"
import notFoundImage from "../images/not-found-image.svg"


type Props = {
  pageContext: {
    language: 'fi' | 'en'
  }
  location: Location
}

const NotFound = ({ pageContext, location }: Props) => {
  const { language } = pageContext
  return (
    <div>
      <Layout location={location} locale={language}>
        <main
          style={{ height: '80vh' }}
          className="max-w-4xl flex-grow mx-auto flex flex-col justify-around"
        >
          <img className="p-4" src={notFoundImage} alt="Page not found" />
        </main>
      </Layout>
    </div>
  )
}

export default NotFound
